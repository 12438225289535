import { ReactNode } from "react";
import { Avatar, Button, Flex, IconButton, Text } from "@radix-ui/themes";
import { VectorTail } from "@/app/components/Vector";
import { getAvatarImageURL } from "@/app/utils/imageUtils";
import styles from "./ReplyToChatPreview.module.scss";
import { CrossCircledIcon } from "@radix-ui/react-icons";

interface ReplyToChatPreviewProps {
  avatarId: string;
  displayName: string;
  children: ReactNode;
  onCancel?: () => void;
  onClick?: () => void;
}

const ReplyToChatPreview = ({
  avatarId,
  displayName,
  children,
  onCancel,
  onClick,
}: ReplyToChatPreviewProps) => (
  <Flex align="center" gap="2" className={styles.replyToChatPreview}>
    <VectorTail
      style={{
        marginTop: "7px", // manual adjustment for optical alignment
      }}
    />

    {/*TODO: not ideal that this is Flex and not a Button*/}
    <Flex
      onClick={onClick}
      align="center"
      className={`${styles.messageContainer} ${onClick && styles.clickable}`}
    >
      <Avatar
        size="1"
        mr="2"
        src={getAvatarImageURL(avatarId)}
        fallback={displayName[0]}
      />
      <Text
        size="1"
        weight="medium"
        mr="1"
        style={{
          whiteSpace: "nowrap",
        }}
      >
        {displayName}
      </Text>
      <Flex align="center">{children}</Flex>
    </Flex>
    {onCancel && (
      <IconButton
        ml="1"
        variant="ghost"
        size="1"
        color="red"
        onClick={onCancel}
      >
        <CrossCircledIcon />
      </IconButton>
    )}
  </Flex>
);

export default ReplyToChatPreview;
