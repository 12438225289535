import { IconType } from "@/app/components/icons/icon.type";

const ZIPFileIcon = ({ style, className }: IconType) => (
  <svg
    style={style}
    className={className}
    width="33"
    height="38"
    viewBox="0 0 33 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_466_29866)">
      <rect
        x="0.620117"
        width="32"
        height="38"
        rx="6"
        fill="#8DA4EF"
        stroke="#01012E"
        strokeOpacity="0.134"
        strokeWidth="0.59375"
      />
      <g clipPath="url(#clip1_466_29866)">
        <path
          d="M9.73633 0.419922H10.2363V1.41992H9.73633V0.419922ZM6.60133 1.41992C6.32519 1.41992 6.10133 1.19606 6.10133 0.919922C6.10133 0.643779 6.32519 0.419922 6.60133 0.419922V1.41992ZM9.73633 1.41992H6.60133V0.419922H9.73633V1.41992Z"
          fill="#F8FAFF"
        />
        <path
          d="M8.62378 1.62012H8.12378V2.62012H8.62378V1.62012ZM11.7588 2.62012C12.0349 2.62012 12.2588 2.39626 12.2588 2.12012C12.2588 1.84397 12.0349 1.62012 11.7588 1.62012V2.62012ZM8.62378 2.62012H11.7588V1.62012H8.62378V2.62012Z"
          fill="#F8FAFF"
        />
        <path
          d="M9.73633 2.83984H10.2363V3.83984H9.73633V2.83984ZM6.60133 3.83984C6.32519 3.83984 6.10133 3.61599 6.10133 3.33984C6.10133 3.0637 6.32519 2.83984 6.60133 2.83984V3.83984ZM9.73633 3.83984H6.60133V2.83984H9.73633V3.83984Z"
          fill="#F8FAFF"
        />
        <path
          d="M8.62378 4.04004H8.12378V5.04004H8.62378V4.04004ZM11.7588 5.04004C12.0349 5.04004 12.2588 4.81618 12.2588 4.54004C12.2588 4.2639 12.0349 4.04004 11.7588 4.04004V5.04004ZM8.62378 5.04004H11.7588V4.04004H8.62378V5.04004Z"
          fill="#F8FAFF"
        />
        <path
          d="M9.73633 5.25977H10.2363V6.25977H9.73633V5.25977ZM6.60133 6.25977C6.32519 6.25977 6.10133 6.03591 6.10133 5.75977C6.10133 5.48362 6.32519 5.25977 6.60133 5.25977V6.25977ZM9.73633 6.25977H6.60133V5.25977H9.73633V6.25977Z"
          fill="#F8FAFF"
        />
        <path
          d="M8.62378 6.45996H8.12378V7.45996H8.62378V6.45996ZM11.7588 7.45996C12.0349 7.45996 12.2588 7.2361 12.2588 6.95996C12.2588 6.68382 12.0349 6.45996 11.7588 6.45996V7.45996ZM8.62378 7.45996H11.7588V6.45996H8.62378V7.45996Z"
          fill="#F8FAFF"
        />
        <path
          d="M9.73633 7.67969H10.2363V8.67969H9.73633V7.67969ZM6.60133 8.67969C6.32519 8.67969 6.10133 8.45583 6.10133 8.17969C6.10133 7.90355 6.32519 7.67969 6.60133 7.67969V8.67969ZM9.73633 8.67969H6.60133V7.67969H9.73633V8.67969Z"
          fill="#F8FAFF"
        />
        <path
          d="M8.62378 8.87988H8.12378V9.87988H8.62378V8.87988ZM11.7588 9.87988C12.0349 9.87988 12.2588 9.65603 12.2588 9.37988C12.2588 9.10374 12.0349 8.87988 11.7588 8.87988V9.87988ZM8.62378 9.87988H11.7588V8.87988H8.62378V9.87988Z"
          fill="#F8FAFF"
        />
        <path
          d="M9.73633 10.1001H10.2363V11.1001H9.73633V10.1001ZM6.60133 11.1001C6.32519 11.1001 6.10133 10.8762 6.10133 10.6001C6.10133 10.324 6.32519 10.1001 6.60133 10.1001V11.1001ZM9.73633 11.1001H6.60133V10.1001H9.73633V11.1001Z"
          fill="#F8FAFF"
        />
        <path
          d="M8.62378 11.2998H8.12378V12.2998H8.62378V11.2998ZM11.7588 12.2998C12.0349 12.2998 12.2588 12.0759 12.2588 11.7998C12.2588 11.5237 12.0349 11.2998 11.7588 11.2998V12.2998ZM8.62378 12.2998H11.7588V11.2998H8.62378V12.2998Z"
          fill="#F8FAFF"
        />
        <path
          d="M10.1102 13.5601C10.3002 13.5601 10.4502 13.7101 10.4502 13.9001V14.9501H7.92017V13.9001C7.92017 13.7101 8.07017 13.5601 8.26017 13.5601H10.1102ZM10.1102 12.5601H8.26017C7.52017 12.5601 6.92017 13.1601 6.92017 13.9001V15.9501H11.4502V13.9001C11.4502 13.1601 10.8502 12.5601 10.1102 12.5601Z"
          fill="#F8FAFF"
        />
        <path d="M10.9502 15.78H7.42017V16.3H10.9502V15.78Z" fill="black" />
        <path d="M11.4502 15.28H6.92017V16.8H11.4502V15.28Z" fill="#F8FAFF" />
      </g>
      <g filter="url(#filter0_ddd_466_29866)">
        <path
          d="M33.2401 11.62L21.6201 0C21.6201 5.31 21.6201 7.97 23.2201 9.69C23.3201 9.8 23.4301 9.92 23.5501 10.02C25.2601 11.61 27.9301 11.62 33.2401 11.62Z"
          fill="#000418"
          fillOpacity="0.506"
          shapeRendering="crispEdges"
        />
      </g>
      <path
        d="M21.6202 0.000117302L33.2402 11.6201C33.2402 6.31012 33.2402 3.65012 31.6402 1.93012C31.5402 1.82012 31.4302 1.70012 31.3102 1.60012C29.6002 0.0101166 26.9302 0.000117302 21.6202 0.000117302Z"
        fill="white"
      />
      <path
        d="M6.16277 31V30.0874L9.79203 24.995H6.15566V23.7273H11.7238V24.6399L8.09103 29.7322H11.7309V31H6.16277ZM13.0324 31V25.5455H14.5452V31H13.0324ZM13.7923 24.8423C13.5674 24.8423 13.3745 24.7678 13.2135 24.6186C13.0549 24.4671 12.9756 24.286 12.9756 24.0753C12.9756 23.867 13.0549 23.6882 13.2135 23.5391C13.3745 23.3875 13.5674 23.3118 13.7923 23.3118C14.0173 23.3118 14.209 23.3875 14.3676 23.5391C14.5286 23.6882 14.6091 23.867 14.6091 24.0753C14.6091 24.286 14.5286 24.4671 14.3676 24.6186C14.209 24.7678 14.0173 24.8423 13.7923 24.8423ZM15.907 33.0455V25.5455H17.3985V26.4616H17.466C17.5323 26.3149 17.6281 26.1657 17.7536 26.0142C17.8814 25.8603 18.0472 25.7325 18.2508 25.6307C18.4567 25.5265 18.7124 25.4744 19.0178 25.4744C19.4155 25.4744 19.7825 25.5786 20.1187 25.7869C20.4548 25.9929 20.7235 26.3042 20.9248 26.7209C21.126 27.1352 21.2266 27.6548 21.2266 28.2798C21.2266 28.8883 21.1284 29.402 20.9319 29.821C20.7377 30.2377 20.4726 30.5537 20.1364 30.7692C19.8026 30.9822 19.4286 31.0888 19.0143 31.0888C18.7207 31.0888 18.4709 31.0402 18.265 30.9432C18.0614 30.8461 17.8945 30.7242 17.7643 30.5774C17.6341 30.4283 17.5346 30.2779 17.466 30.1264H17.4198V33.0455H15.907ZM17.3878 28.2727C17.3878 28.5971 17.4328 28.88 17.5228 29.1214C17.6127 29.3629 17.743 29.5511 17.9134 29.6861C18.0839 29.8187 18.291 29.8849 18.5349 29.8849C18.7811 29.8849 18.9894 29.8175 19.1599 29.6825C19.3303 29.5452 19.4593 29.3558 19.5469 29.1143C19.6369 28.8705 19.6819 28.59 19.6819 28.2727C19.6819 27.9579 19.6381 27.6809 19.5505 27.4418C19.4629 27.2027 19.3339 27.0156 19.1634 26.8807C18.993 26.7457 18.7834 26.6783 18.5349 26.6783C18.2886 26.6783 18.0803 26.7434 17.9099 26.8736C17.7418 27.0038 17.6127 27.1884 17.5228 27.4276C17.4328 27.6667 17.3878 27.9484 17.3878 28.2727Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_ddd_466_29866"
        x="17.6201"
        y="-3"
        width="19.6201"
        height="19.6201"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0 0 0 0.051 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_466_29866"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="1"
          operator="erode"
          in="SourceAlpha"
          result="effect2_dropShadow_466_29866"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0 0 0 0.051 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_466_29866"
          result="effect2_dropShadow_466_29866"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.055 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_dropShadow_466_29866"
          result="effect3_dropShadow_466_29866"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect3_dropShadow_466_29866"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_466_29866">
        <path
          d="M0.620117 11.7C0.620117 6.33443 0.620117 3.65165 2.21418 1.9272C2.32082 1.81183 2.43195 1.7007 2.54731 1.59406C4.27176 0 6.95455 0 12.3201 0H20.9201C26.2857 0 28.9685 0 30.6929 1.59406C30.8083 1.7007 30.9194 1.81183 31.0261 1.9272C32.6201 3.65165 32.6201 6.33443 32.6201 11.7V26.3C32.6201 31.6656 32.6201 34.3484 31.0261 36.0728C30.9194 36.1882 30.8083 36.2993 30.6929 36.4059C28.9685 38 26.2857 38 20.9201 38H12.3201C6.95455 38 4.27176 38 2.54731 36.4059C2.43195 36.2993 2.32082 36.1882 2.21418 36.0728C0.620117 34.3484 0.620117 31.6656 0.620117 26.3V11.7Z"
          fill="white"
        />
      </clipPath>
      <clipPath id="clip1_466_29866">
        <rect
          width="7.12"
          height="18.8"
          fill="white"
          transform="translate(5.62012 -2)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ZIPFileIcon;
