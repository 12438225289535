import React from "react";
import { Card, Flex, IconButton, Text } from "@radix-ui/themes";
import AudioFileIcon from "@/app/components/icons/AudioFileIcon";
import PDFFileIcon from "@/app/components/icons/PDFFileIcon";
import WordFileIcon from "@/app/components/icons/WordFileIcon";
import JSONFileIcon from "@/app/components/icons/JSONFileIcon";
import CSVFileIcon from "@/app/components/icons/CSVFileIcon";
import ExcelFileIcon from "@/app/components/icons/ExcelFileIcon";
import PowerPointFileIcon from "@/app/components/icons/PowerPointFileIcon";
import PhotoshopFileIcon from "@/app/components/icons/PhotoshopFileIcon";
import IllustratorFileIcon from "@/app/components/icons/IllustratorFileIcon";
import ZIPFileIcon from "@/app/components/icons/ZIPFileIcon";
import GenericFileIcon from "@/app/components/icons/GenericFileIcon";
import { FileUploadAnimation } from "@/app/components/ChatBox/FileUploadAnimation";
import styles from "./FilePreview.module.scss";
import { DownloadIcon } from "@radix-ui/react-icons";

export interface SelectedFile {
  file: File;
  url: string;
  isLoading: boolean;
}

const renderFilePreview = (
  IconComponent: React.ElementType,
  fileTypeDisplayName: string,
  fileName: string,
  downloadURL?: string,
) => (
  <Card style={{ width: "100%", height: "var(--space-9)" }}>
    <Flex align="center">
      <IconComponent />
      <Flex align="center" justify="between" width="100%">
        <Flex ml="2" direction="column" justify="center">
          <Text
            size="1"
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              maxWidth: 100,
            }}
          >
            {fileName}
          </Text>
          <Text style={{ color: "var(--slate-11)" }} size="1">
            {fileTypeDisplayName}
          </Text>
        </Flex>
        {downloadURL && (
          <a
            style={{ textDecoration: "none", color: "inherit" }}
            href={downloadURL}
            target="_blank"
          >
            <IconButton variant="outline">
              <DownloadIcon />
            </IconButton>
          </a>
        )}
      </Flex>
    </Flex>
  </Card>
);

interface FilePreviewProps {
  img: SelectedFile;
}

export const FileContent = ({
  fileType,
  fileName,
  url,
  downloadURL,
}: {
  fileType: string;
  fileName: string;
  url?: string;
  downloadURL?: string;
}) => {
  if (fileType.startsWith("image/") && downloadURL) {
    return renderFilePreview(GenericFileIcon, "File", fileName, downloadURL);
  }
  if (fileType.startsWith("image/")) {
    return <img className={styles.imagePreview} src={url} alt="Preview" />;
  }

  if (
    fileType === "audio/wav" ||
    fileType === "audio/x-wav" ||
    fileType === "audio/mpeg"
  ) {
    return renderFilePreview(AudioFileIcon, "Audio", fileName, downloadURL);
  }

  if (fileType === "application/pdf") {
    return renderFilePreview(PDFFileIcon, "Adobe PDF", fileName, downloadURL);
  }

  if (
    fileType.startsWith("application/msword") ||
    fileType.startsWith(
      "application/vnd.openxmlformats-officedocument.wordprocessingml",
    )
  ) {
    return renderFilePreview(
      WordFileIcon,
      "Microsoft Word",
      fileName,
      downloadURL,
    );
  }

  if (fileType === "application/json") {
    return renderFilePreview(JSONFileIcon, "JSON", fileName, downloadURL);
  }

  if (fileType === "text/csv") {
    return renderFilePreview(CSVFileIcon, "CSV", fileName, downloadURL);
  }

  if (
    fileType.startsWith("application/vnd.ms-excel") ||
    fileType.startsWith(
      "application/vnd.openxmlformats-officedocument.spreadsheetml",
    )
  ) {
    return renderFilePreview(
      ExcelFileIcon,
      "Microsoft Excel",
      fileName,
      downloadURL,
    );
  }

  if (
    fileType.startsWith("application/vnd.ms-powerpoint") ||
    fileType.startsWith(
      "application/vnd.openxmlformats-officedocument.presentationml",
    )
  ) {
    return renderFilePreview(
      PowerPointFileIcon,
      "Microsoft PowerPoint",
      fileName,
      downloadURL,
    );
  }

  if (fileType === "image/vnd.adobe.photoshop") {
    return renderFilePreview(
      PhotoshopFileIcon,
      "Adobe Photoshop",
      fileName,
      downloadURL,
    );
  }

  if (
    fileType === "application/postscript" ||
    fileType === "image/x-illustrator"
  ) {
    return renderFilePreview(
      IllustratorFileIcon,
      "Adobe Illustrator",
      fileName,
      downloadURL,
    );
  }

  if (fileType === "application/zip") {
    return renderFilePreview(ZIPFileIcon, "ZIP", fileName, downloadURL);
  }

  return renderFilePreview(GenericFileIcon, "File", fileName, downloadURL);
};

export const FilePreview = ({ img }: FilePreviewProps) => {
  const fileType = img.file.type;
  const fileName = img.file.name;
  const { isLoading } = img;

  return (
    <div className={styles.filePreview}>
      <Flex
        className={styles.filePreviewContent}
        style={{
          opacity: isLoading ? 0.5 : 1,
        }}
      >
        <FileContent fileType={fileType} fileName={fileName} url={img.url} />
      </Flex>

      {isLoading && (
        <FileUploadAnimation
          style={{
            position: "absolute",
          }}
        />
      )}
    </div>
  );
};
