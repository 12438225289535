import { useCallback } from "react";
import {
  getAllThreadsAPI,
  getThreadByIdAPI,
  createThreadAPI,
  StructAPIThreadsResponse,
  putDeleteThreadsAPI,
  StructThreadAPIResponse,
  putHideThreadsAPI,
  putUnhideThreadsAPI,
} from "@/app/services/threads";

import {
  StructChat,
  StructThread,
  StructThreadResponse,
} from "@/app/types/Thread.type";
import { getSearchResultsAPI } from "@/app/services/search";
import { useAppStore } from "../store";
import useShallowNavigation from "./useShallowNavigation";
import { notification } from "antd";

const useThreads = () => {
  const organisation = useAppStore((state) => state.organisation);
  const { navigateToPath } = useShallowNavigation();

  const { id: organisationId } = organisation;

  const fetchAllThreads = useCallback(
    (cursor?: string): Promise<StructAPIThreadsResponse | any> =>
      getAllThreadsAPI({ cursor }).catch((error) => {
        console.log(error);
      }),
    [organisationId],
  );

  const fetchThreadById = useCallback(
    (threadId: string): Promise<StructThreadAPIResponse | any> =>
      getThreadByIdAPI(threadId).catch((error) => {
        console.log(error);
      }),
    [organisationId],
  );

  const fetchSimilarThreads = useCallback(
    ({ query }: { query: string }): Promise<any> =>
      getSearchResultsAPI({
        q: query,
      }).catch((error) => {
        console.log(error);
      }),
    [organisationId],
  );

  const deleteThread = useCallback(
    (threadId: string) =>
      putDeleteThreadsAPI([threadId]).catch((error) => {
        console.log(error);
      }),
    [organisationId],
  );

  const deleteThreads = useCallback(
    (threadIds: string[]) =>
      putDeleteThreadsAPI(threadIds).catch((error) => {
        console.log(error);
      }),
    [organisationId],
  );

  const hideThreadsForAll = useCallback(
    (threadIds: string[]) =>
      putHideThreadsAPI(threadIds).catch((error) => {
        console.log(error);
      }),
    [organisationId],
  );

  const unhideThreadsForAll = useCallback(
    (threadIds: string[]) =>
      putUnhideThreadsAPI(threadIds).catch((error) => {
        console.log(error);
      }),
    [organisationId],
  );

  const createThread = useCallback(
    ({
      message,
      channelIds,
      attachments,
    }: {
      message: string;
      channelIds: StructThread["xsr_ids"];
      attachments?: StructChat["attachments"];
    }): Promise<StructThreadResponse | any> =>
      createThreadAPI({
        message,
        channelIds,
        attachments,
      }).catch((error) => {
        console.log(error);
      }),
    [organisationId],
  );

  const handleThreadNotFound = () => {
    navigateToPath(null);
    notification.error({
      message: "Thread not found",
      description:
        "The thread you are trying to access does not exist or you do not have permission to view it.",

      duration: 5,
    });
    console.error("Thread not found");
  };

  return {
    fetchAllThreads,
    fetchThreadById,
    fetchSimilarThreads,
    createThread,
    deleteThread,
    deleteThreads,
    handleThreadNotFound,
    hideThreadsForAll,
    unhideThreadsForAll,
  };
};

export default useThreads;
