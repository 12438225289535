import { IconType } from "@/app/components/icons/icon.type";

const JSONFileIcon = ({ style, className }: IconType) => (
  <svg
    style={style}
    className={className}
    width="33"
    height="38"
    viewBox="0 0 33 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_467_30952)">
      <rect
        x="0.620117"
        width="32"
        height="38"
        rx="6"
        fill="#8DA4EF"
        stroke="#01012E"
        strokeOpacity="0.134"
        strokeWidth="0.59375"
      />
      <g filter="url(#filter0_ddd_467_30952)">
        <path
          d="M33.2401 11.62L21.6201 0C21.6201 5.31 21.6201 7.97 23.2201 9.69C23.3201 9.8 23.4301 9.92 23.5501 10.02C25.2601 11.61 27.9301 11.62 33.2401 11.62Z"
          fill="#000418"
          fillOpacity="0.506"
          shapeRendering="crispEdges"
        />
      </g>
      <path
        d="M21.6202 0.000117302L33.2402 11.6201C33.2402 6.31012 33.2402 3.65012 31.6402 1.93012C31.5402 1.82012 31.4302 1.70012 31.3102 1.60012C29.6002 0.0101166 26.9302 0.000117302 21.6202 0.000117302Z"
        fill="white"
      />
      <rect
        width="16"
        height="16"
        transform="translate(7.62012 12)"
        fill="white"
        fillOpacity="0.01"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.2486 14.8649C18.358 14.5914 18.225 14.281 17.9515 14.1716C17.678 14.0622 17.3676 14.1952 17.2582 14.4687L12.9916 25.1354C12.8822 25.4088 13.0152 25.7192 13.2887 25.8286C13.5622 25.938 13.8726 25.805 13.982 25.5315L18.2486 14.8649ZM11.7306 17.4897C11.9388 17.6979 11.9388 18.0356 11.7306 18.2439L9.97435 20.0001L11.7306 21.7563C11.9388 21.9646 11.9388 22.3023 11.7306 22.5106C11.5223 22.7188 11.1846 22.7188 10.9763 22.5106L8.84298 20.3772C8.6347 20.169 8.6347 19.8313 8.84298 19.623L10.9763 17.4897C11.1846 17.2814 11.5223 17.2814 11.7306 17.4897ZM19.5096 17.9165C19.7179 17.7083 20.0556 17.7083 20.2639 17.9165L22.3973 20.0499C22.6055 20.2582 22.6055 20.5958 22.3973 20.8041L20.2639 22.9375C20.0556 23.1457 19.7179 23.1457 19.5096 22.9375C19.3014 22.7292 19.3014 22.3915 19.5096 22.1832L21.2659 20.427L19.5096 18.6708C19.3014 18.4625 19.3014 18.1248 19.5096 17.9165Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_ddd_467_30952"
        x="17.6201"
        y="-3"
        width="19.6201"
        height="19.6201"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0 0 0 0.051 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_467_30952"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="1"
          operator="erode"
          in="SourceAlpha"
          result="effect2_dropShadow_467_30952"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0 0 0 0.051 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_467_30952"
          result="effect2_dropShadow_467_30952"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.055 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_dropShadow_467_30952"
          result="effect3_dropShadow_467_30952"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect3_dropShadow_467_30952"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_467_30952">
        <path
          d="M0.620117 11.7C0.620117 6.33443 0.620117 3.65165 2.21418 1.9272C2.32082 1.81183 2.43195 1.7007 2.54731 1.59406C4.27176 0 6.95455 0 12.3201 0H20.9201C26.2857 0 28.9685 0 30.6929 1.59406C30.8083 1.7007 30.9194 1.81183 31.0261 1.9272C32.6201 3.65165 32.6201 6.33443 32.6201 11.7V26.3C32.6201 31.6656 32.6201 34.3484 31.0261 36.0728C30.9194 36.1882 30.8083 36.2993 30.6929 36.4059C28.9685 38 26.2857 38 20.9201 38H12.3201C6.95455 38 4.27176 38 2.54731 36.4059C2.43195 36.2993 2.32082 36.1882 2.21418 36.0728C0.620117 34.3484 0.620117 31.6656 0.620117 26.3V11.7Z"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);

export default JSONFileIcon;
