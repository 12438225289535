import { IconType } from "@/app/components/icons/icon.type";

const AudioFileIcon = ({ style, className }: IconType) => (
  <svg
    style={style}
    className={className}
    width="33"
    height="38"
    viewBox="0 0 33 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_466_30263)">
      <rect
        x="0.620117"
        width="32"
        height="38"
        rx="6"
        fill="#8DA4EF"
        stroke="#01012E"
        strokeOpacity="0.134"
        strokeWidth="0.59375"
      />
      <g filter="url(#filter0_ddd_466_30263)">
        <path
          d="M33.2401 11.62L21.6201 0C21.6201 5.31 21.6201 7.97 23.2201 9.69C23.3201 9.8 23.4301 9.92 23.5501 10.02C25.2601 11.61 27.9301 11.62 33.2401 11.62Z"
          fill="#000418"
          fillOpacity="0.506"
          shapeRendering="crispEdges"
        />
      </g>
      <path
        d="M21.6202 0.000117302L33.2402 11.6201C33.2402 6.31012 33.2402 3.65012 31.6402 1.93012C31.5402 1.82012 31.4302 1.70012 31.3102 1.60012C29.6002 0.0101166 26.9302 0.000117302 21.6202 0.000117302Z"
        fill="white"
      />
      <rect
        width="16"
        height="16"
        transform="translate(7.62012 12)"
        fill="white"
        fillOpacity="0.01"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5878 13.1211C15.7708 13.2106 15.8868 13.3965 15.8868 13.6002V26.4002C15.8868 26.604 15.7708 26.7899 15.5878 26.8794C15.4048 26.9689 15.1868 26.9463 15.026 26.8212L11.0562 23.7336H9.22012C8.33646 23.7336 7.62012 23.0172 7.62012 22.1336V17.8669C7.62012 16.9832 8.33646 16.2669 9.22012 16.2669H11.0562L15.026 13.1792C15.1868 13.0542 15.4048 13.0316 15.5878 13.1211ZM14.8201 14.6907L11.5666 17.2212C11.473 17.294 11.3578 17.3336 11.2392 17.3336H9.22012C8.92557 17.3336 8.68678 17.5723 8.68678 17.8669V22.1336C8.68678 22.4281 8.92557 22.6669 9.22012 22.6669H11.2392C11.3578 22.6669 11.473 22.7065 11.5666 22.7792L14.8201 25.3098V14.6907ZM18.5837 16.1048C18.7799 15.9743 19.0448 16.0275 19.1753 16.2237C20.693 18.5047 20.693 21.4957 19.1753 23.7768C19.0448 23.9729 18.7799 24.0261 18.5837 23.8956C18.3875 23.7651 18.3342 23.5003 18.4648 23.304C19.792 21.3093 19.792 18.6911 18.4648 16.6964C18.3342 16.5002 18.3875 16.2353 18.5837 16.1048ZM21.1439 13.526C20.9914 13.3463 20.7222 13.324 20.5425 13.4763C20.3627 13.6287 20.3404 13.8979 20.4928 14.0777C23.3826 17.4881 23.3826 22.5124 20.4928 25.9228C20.3404 26.1025 20.3627 26.3717 20.5425 26.5241C20.7222 26.6765 20.9914 26.6542 21.1439 26.4745C24.3034 22.7457 24.3034 17.2548 21.1439 13.526Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_ddd_466_30263"
        x="17.6201"
        y="-3"
        width="19.6201"
        height="19.6201"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0 0 0 0.051 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_466_30263"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="1"
          operator="erode"
          in="SourceAlpha"
          result="effect2_dropShadow_466_30263"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0 0 0 0.051 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_466_30263"
          result="effect2_dropShadow_466_30263"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.055 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_dropShadow_466_30263"
          result="effect3_dropShadow_466_30263"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect3_dropShadow_466_30263"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_466_30263">
        <path
          d="M0.620117 11.7C0.620117 6.33443 0.620117 3.65165 2.21418 1.9272C2.32082 1.81183 2.43195 1.7007 2.54731 1.59406C4.27176 0 6.95455 0 12.3201 0H20.9201C26.2857 0 28.9685 0 30.6929 1.59406C30.8083 1.7007 30.9194 1.81183 31.0261 1.9272C32.6201 3.65165 32.6201 6.33443 32.6201 11.7V26.3C32.6201 31.6656 32.6201 34.3484 31.0261 36.0728C30.9194 36.1882 30.8083 36.2993 30.6929 36.4059C28.9685 38 26.2857 38 20.9201 38H12.3201C6.95455 38 4.27176 38 2.54731 36.4059C2.43195 36.2993 2.32082 36.1882 2.21418 36.0728C0.620117 34.3484 0.620117 31.6656 0.620117 26.3V11.7Z"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);

export default AudioFileIcon;
