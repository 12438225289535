import React from "react";
import * as PopoverPrimitive from "@radix-ui/react-popover";

// const Popover = ({ children, content }: PopoverProps) => {
//   return (
//     <RadixPopover.Root>
//       <RadixPopover.Trigger asChild>{children}</RadixPopover.Trigger>
//       <RadixPopover.Anchor />
//       <RadixPopover.Portal>
//         <RadixPopover.Content>
//             <EmojiPicker />
//           {/*{content}*/}
//           {/*<RadixPopover.Close />*/}
//           {/*<RadixPopover.Arrow />*/}
//         </RadixPopover.Content>
//       </RadixPopover.Portal>
//     </RadixPopover.Root>
//   );
// };
//
// export default Popover;

type PopoverContentProps = {
  children: React.ReactNode;
  // any other props you expect to be there
};

export const Popover = PopoverPrimitive.Root;
export const PopoverTrigger = PopoverPrimitive.Trigger;

export const PopoverContent = React.forwardRef<
  HTMLDivElement,
  PopoverContentProps
>(({ children, ...props }, forwardedRef) => (
  <PopoverPrimitive.Portal>
    <PopoverPrimitive.Content
      style={{ zIndex: 999 }}
      sideOffset={5}
      {...props}
      ref={forwardedRef}
    >
      {children}
      <PopoverPrimitive.Arrow />
    </PopoverPrimitive.Content>
  </PopoverPrimitive.Portal>
));

PopoverContent.displayName = "PopoverContent";
