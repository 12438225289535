"use client";
import React from "react";

export const Vector = ({ style }: { style?: React.CSSProperties }) => (
  <svg
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="32"
    viewBox="0 0 16 32"
    fill="none"
  >
    <path d="M1 0V23C1 27.4183 4.58172 31 9 31H16" stroke="var(--slate-6)" />
  </svg>
);

export const VectorLong = ({ style }: { style?: React.CSSProperties }) => (
  <svg
    style={style}
    width="16"
    height="48"
    viewBox="0 0 16 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 0V39C1 43.4183 4.58172 47 9 47H16" stroke="var(--slate-6)" />
  </svg>
);

export const VectorTail = ({ style }: { style?: React.CSSProperties }) => (
  <svg
    style={style}
    width="33"
    height="13"
    viewBox="0 0 33 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 12.2803V11.7803C1 5.70514 5.92487 0.780273 12 0.780273H33"
      stroke="var(--slate-6)"
    />
  </svg>
);
