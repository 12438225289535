import { useCallback } from "react";
import {
  createChatMessageAPI,
  deleteChatMessageAPI,
  updateChatMessageAPI,
  fetchChatsAPI,
  postReactionsAPI,
  postChatReadAPI,
  forkChatsAPI,
} from "@/app/services/chats";
import {
  StructChat,
  StructChatResponse,
  StructThreadResponse,
} from "@/app/types/Thread.type";

import { useAppStore } from "../store";

const useChats = () => {
  const organisation = useAppStore((state) => state.organisation);

  const { id: orgId } = organisation;

  const deleteChatMessage = useCallback(
    ({
      threadId,
      messageId,
    }: {
      threadId: StructThreadResponse["id"];
      messageId: StructChatResponse["id"];
    }) =>
      deleteChatMessageAPI({
        messageId,
        threadId,
      }).catch((error) => {
        console.log(error);
      }),
    [orgId],
  );

  const createChatMessage = useCallback(
    ({
      threadId,
      message,
      attachments,
      reply_to,
    }: {
      threadId: StructThreadResponse["id"];
      message: StructChat["text"];
      attachments?: StructChat["attachments"];
      reply_to?: StructChat["reply_to"];
    }) =>
      createChatMessageAPI({
        threadId,
        message,
        attachments,
        reply_to,
      }).catch((error) => {
        console.log(error);
      }),
    [orgId],
  );

  const updateChatMessage = useCallback(
    ({
      threadId,
      messageId,
      message,
    }: {
      threadId: StructThreadResponse["id"];
      messageId: StructChatResponse["id"];
      message: StructChat["text"];
    }) =>
      updateChatMessageAPI({
        messageId,
        threadId,
        message,
      }).catch((error) => {
        console.log(error);
      }),
    [orgId],
  );

  const fetchChatMessages = useCallback(
    ({
      threadId,
      messageCount = 100,
      beforeChatId,
      showError = true,
    }: {
      threadId: string;
      messageCount?: number;
      beforeChatId?: string;
      showError?: boolean;
    }) =>
      fetchChatsAPI({
        threadId,
        messageCount,
        beforeChatId,
        showError,
      }).catch((error) => {
        console.log(error);
      }),
    [orgId],
  );

  const setReaction = useCallback(
    (messageId: string, name: string) =>
      postReactionsAPI({
        messageId,
        name,
        action: "set",
      }).catch((error) => {
        console.log(error);
      }),
    [orgId],
  );

  const deleteReaction = useCallback(
    (messageId: string, name: string) =>
      postReactionsAPI({
        messageId,
        name,
        action: "delete",
      }).catch((error) => {
        console.log(error);
      }),
    [orgId],
  );

  const markChatAsRead = useCallback(
    ({ threadId }: { threadId: StructThreadResponse["id"] }) =>
      postChatReadAPI({
        threadId,
      }).catch((error) => {
        console.log(error);
      }),
    [],
  );

  const forkChats = useCallback(
    ({ chatIds }: { chatIds: StructChatResponse["id"][] }) =>
      forkChatsAPI({
        chatIds,
      }).catch((error) => {
        console.log(error);
      }),
    [],
  );

  return {
    deleteChatMessage,
    createChatMessage,
    updateChatMessage,
    fetchChatMessages,
    setReaction,
    deleteReaction,
    markChatAsRead,
    forkChats,
  };
};

export default useChats;
