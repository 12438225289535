import { IconType } from "@/app/components/icons/icon.type";

const ExcelFileIcon = ({ style, className }: IconType) => (
  <svg
    style={style}
    className={className}
    width="33"
    height="38"
    viewBox="0 0 33 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_466_30222)">
      <rect
        x="0.620117"
        width="32"
        height="38"
        rx="6"
        fill="#299764"
        stroke="#01012E"
        strokeOpacity="0.134"
        strokeWidth="0.59375"
      />
      <g filter="url(#filter0_ddd_466_30222)">
        <path
          d="M33.2401 11.62L21.6201 0C21.6201 5.31 21.6201 7.97 23.2201 9.69C23.3201 9.8 23.4301 9.92 23.5501 10.02C25.2601 11.61 27.9301 11.62 33.2401 11.62Z"
          fill="#000418"
          fillOpacity="0.506"
          shapeRendering="crispEdges"
        />
      </g>
      <path
        d="M21.6202 0.000117302L33.2402 11.6201C33.2402 6.31012 33.2402 3.65012 31.6402 1.93012C31.5402 1.82012 31.4302 1.70012 31.3102 1.60012C29.6002 0.0101166 26.9302 0.000117302 21.6202 0.000117302Z"
        fill="white"
      />
      <path
        d="M13.5196 15.3182L15.5729 18.7884H15.6524L17.7156 15.3182H20.1468L17.0395 20.4091L20.2164 25.5H17.7405L15.6524 22.0249H15.5729L13.4848 25.5H11.0189L14.2057 20.4091L11.0786 15.3182H13.5196Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_ddd_466_30222"
        x="17.6201"
        y="-3"
        width="19.6201"
        height="19.6201"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0 0 0 0.051 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_466_30222"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="1"
          operator="erode"
          in="SourceAlpha"
          result="effect2_dropShadow_466_30222"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0 0 0 0.051 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_466_30222"
          result="effect2_dropShadow_466_30222"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.055 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_dropShadow_466_30222"
          result="effect3_dropShadow_466_30222"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect3_dropShadow_466_30222"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_466_30222">
        <path
          d="M0.620117 11.7C0.620117 6.33443 0.620117 3.65165 2.21418 1.9272C2.32082 1.81183 2.43195 1.7007 2.54731 1.59406C4.27176 0 6.95455 0 12.3201 0H20.9201C26.2857 0 28.9685 0 30.6929 1.59406C30.8083 1.7007 30.9194 1.81183 31.0261 1.9272C32.6201 3.65165 32.6201 6.33443 32.6201 11.7V26.3C32.6201 31.6656 32.6201 34.3484 31.0261 36.0728C30.9194 36.1882 30.8083 36.2993 30.6929 36.4059C28.9685 38 26.2857 38 20.9201 38H12.3201C6.95455 38 4.27176 38 2.54731 36.4059C2.43195 36.2993 2.32082 36.1882 2.21418 36.0728C0.620117 34.3484 0.620117 31.6656 0.620117 26.3V11.7Z"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ExcelFileIcon;
